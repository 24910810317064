<template>
	<div></div>
</template>

<script>
export default {
	name: 'Home',

	data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
